@import '../conf';

.oferta {

}

.oferta__content {
    display: flex;
    flex-direction: column;

    p {
        @include rfs(18);
        line-height: 1.77;
        margin-bottom: 2.5rem;
    }

}

.oferta__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 767px) {
    .oferta__buttons {
        width: 100%;
        max-width: 260px;
        margin: 0 auto;

        .btn {
            width: 100%;
            margin-bottom: .5rem;
        }
    }
}


@media screen and (min-width: 768px) {

    .oferta__content {
        p {
            @include rfs(18);
            width: 70%;
    
            &:nth-of-type(2n+1) {
                align-self: flex-start;
                font-weight: $font-w-medium;
            }
    
            &:nth-of-type(2n+2) {
                align-self: flex-end;
            }
        }
    }

    .oferta__buttons {
        flex-direction: row;

        .btn:not(:last-child) {
            margin-right: .85rem;
        }
    }
}